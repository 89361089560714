export const BEGIN_AJAX_CALL = 'BEGIN_AJAX_CALL';

export const END_AJAX_CALL = 'END_AJAX_CALL';

export const API_ERROR_RESPONSE = 'API_ERROR_RESPONSE';

export const FETCH_MASTER_DATA = 'FETCH_MASTER_DATA';

export const FETCH_BRAND_RESOURCES = 'FETCH_BRAND_RESOURCES';

export const FETCH_OUTLET_DETAILS = 'FETCH_OUTLET_DETAILS';

export const DISPATCH_CONTEXTUAL_HOME = 'DISPATCH_CONTEXTUAL_HOME';

export const FETCH_APP_STARTUP_INFO = 'FETCH_APP_STARTUP_INFO';

export const DISPATCH_BRAND_RESOURCES = 'DISPATCH_BRAND_RESOURCES';

export const FETCH_BRAND_CITIES = 'FETCH_BRAND_CITIES';

export const DISPATCH_APPLICABLE_BRAND_MODULES = 'DISPATCH_APPLICABLE_BRAND_MODULES';

export const DISPATCH_GUEST_LOCATION = 'DISPATCH_GUEST_LOCATION';

export const DISPATCH_APP_RESOLUTION_CONTEXT = 'DISPATCH_APP_RESOLUTION_CONTEXT';

export const DISPATCH_GUEST_COUPON_AND_DEAL_DATA = 'DISPATCH_GUEST_COUPON_AND_DEAL_DATA';
