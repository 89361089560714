export const FETCH_RESTAURANT_MENU = 'FETCH_RESTAURANT_MENU';

export const FETCH_RESTAURANT_DATA = 'FETCH_RESTAURANT_DATA';

export const UPDATE_MENU = 'UPDATE_MENU';

export const FETCH_MENU_ITEM_CUSTOMIZATIONS = 'FETCH_MENU_ITEM_CUSTOMIZATIONS';

export const FETCH_VIEW_CART = 'FETCH_VIEW_CART';

export const PLACE_ORDER = 'PLACE_ORDER';

export const FETCH_ORDER_STATUS = 'FETCH_ORDER_STATUS';

export const FETCH_ORDERED_ITEMS = 'FETCH_ORDERED_ITEMS';

export const CLEAR_SELECTED_TABLE = 'CLEAR_SELECTED_TABLE';

export const ADD_GUEST_ADDRESS = 'ADD_GUEST_ADDRESS';

export const UPDATE_GUEST_ADDRESSES = 'UPDATE_GUEST_ADDRESSES';

export const DISPATCH_UPDATED_GUEST_ADDRESSES = 'DISPATCH_UPDATED_GUEST_ADDRESSES';

export const CLEAR_SELECTED_ADDRESS = 'CLEAR_SELECTED_ADDRESS';

export const CLEAR_UPDATED_SCAN_CODE = 'CLEAR_UPDATED_SCAN_CODE';

export const FETCH_GUEST_ORDERS = 'FETCH_GUEST_ORDERS';

export const DISABLED_MENU_ITEMS = 'DISABLED_MENU_ITEMS';

export const FETCH_SELECTED_TABLE = 'FETCH_SELECTED_TABLE';

export const PREVIOUS_SELECTED_TABLE = 'PREVIOUS_SELECTED_TABLE';

export const DISPATCH_SELECTED_ADDRESS = 'DISPATCH_SELECTED_ADDRESS';

export const DISPATCH_DRAFT_SELECTED_ADDRESS = 'DISPATCH_DRAFT_SELECTED_ADDRESS';

export const DISPATCH_RESTAURANT_GROUP = 'DISPATCH_RESTAURANT_GROUP';

export const DISPATCH_CUSTOMIZATION_LOADER = 'DISPATCH_CUSTOMIZATION_LOADER';

export const FETCH_AVAILABLE_OFFERS = 'FETCH_AVAILABLE_OFFERS';

export const FETCH_RECENTLY_VISITED_RESTAURANT = 'FETCH_RECENTLY_VISITED_RESTAURANT';

export const FETCH_GUEST_PREVIOUS_ORDERED_ITEMS = 'FETCH_GUEST_PREVIOUS_ORDERED_ITEMS';

export const CLEAR_GUEST_PREVIOUS_ORDERED_ITEMS = 'CLEAR_GUEST_PREVIOUS_ORDERED_ITEMS';

export const FETCH_GUEST_ORDER_COUNT = 'FETCH_GUEST_ORDER_COUNT';

export const FETCH_MENU_ITEM_VARIATIONS = 'FETCH_MENU_ITEM_VARIATIONS';

export const DELETE_GUEST_ADDRESS = 'DELETE_GUEST_ADDRESS';

export const FETCH_GUEST_TABLE_RESERVATIONS = 'FETCH_GUEST_TABLE_RESERVATIONS';

export const DISPATCH_REWARD_DEALS = 'DISPATCH_REWARD_DEALS';

export const DISPATCH_DEAL_OFFER_DATA = 'DISPATCH_DEAL_OFFER_DATA';
