import { get, isEmpty } from 'lodash';
import {
  fetchBrandResourcesApi,
  fetchMasterDataApi,
  fetchOutletDetailsApi,
  fetchAppStartupInfoApi,
  fetchBrandCitiesApi,
} from '../../api/api';
import {
  API_ERROR_RESPONSE,
  BEGIN_AJAX_CALL,
  DISPATCH_APPLICABLE_BRAND_MODULES,
  DISPATCH_BRAND_RESOURCES,
  DISPATCH_CONTEXTUAL_HOME,
  DISPATCH_GUEST_LOCATION,
  END_AJAX_CALL,
  FETCH_APP_STARTUP_INFO,
  FETCH_BRAND_CITIES,
  FETCH_BRAND_RESOURCES,
  FETCH_MASTER_DATA,
  FETCH_OUTLET_DETAILS,
  DISPATCH_APP_RESOLUTION_CONTEXT,
  CLEAR_APP_RESOLUTION_CONTEXT,
  DISPATCH_GUEST_COUPON_AND_DEAL_DATA,
} from './actionType';

export const beginAjaxCallAction = () => async (dispatch) => {
  dispatch({
    type: BEGIN_AJAX_CALL,
    showSpinner: true,
  });
};

export const endAjaxCallAction = () => async (dispatch) => {
  dispatch({
    type: END_AJAX_CALL,
    showSpinner: false,
  });
};

export const apiErrorResponseAction = (errorResponse) => async (dispatch) => {
  dispatch({
    type: API_ERROR_RESPONSE,
    errorResponse,
  });
  dispatch({
    type: END_AJAX_CALL,
    showSpinner: false,
  });
};

export const clearApiErrorResponseAction = () => async (dispatch) => {
  dispatch({
    type: API_ERROR_RESPONSE,
    errorResponse: {},
  });
};

export const fetchMasterDataAction =
  ({ appName }) =>
  async (dispatch) => {
    dispatch({
      type: BEGIN_AJAX_CALL,
      showSpinner: true,
    });
    fetchMasterDataApi({ appName })
      .then((response) => {
        dispatch({
          type: FETCH_MASTER_DATA,
          masterData: get(response, 'data', {}),
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() =>
        dispatch({
          type: END_AJAX_CALL,
          showSpinner: false,
        }),
      );
  };

export const fetchBrandResourcesAction =
  ({ brandId }) =>
  async (dispatch) => {
    const resourceResponse = await fetchBrandResourcesApi({ brandId })
      .then((response) => {
        dispatch({
          type: FETCH_BRAND_RESOURCES,
          resources: get(response, 'data.resource', {}),
        });
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
    return resourceResponse;
  };

export const fetchBrandCitiesAction =
  ({ brandId }) =>
  async (dispatch) => {
    try {
      const citiesResponse = await fetchBrandCitiesApi({ brandId: brandId })
        .then((response) => {
          const cities = get(response, 'data.cities', []);
          dispatch({
            type: FETCH_BRAND_CITIES,
            brandCities: cities,
          });
          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });
      return citiesResponse;
    } catch (err) {
      console.error(err);
    }
  };

export const fetchOutletDetailsAction =
  ({ url }, withLoader = true) =>
  async (dispatch) => {
    try {
      withLoader && dispatch(beginAjaxCallAction());
      const outletDetailsResponse = await fetchOutletDetailsApi({ url })
        .then((response) => {
          const outletDetails = get(response, 'data', {});
          dispatch({
            type: FETCH_OUTLET_DETAILS,
            outletDetails,
          });
          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });
      return outletDetailsResponse;
    } catch (err) {
      console.error(err);
    } finally {
      withLoader && dispatch(endAjaxCallAction());
    }
  };

export const dispatchContextualHome = (contextualHome) => (dispatch) => {
  dispatch({
    type: DISPATCH_CONTEXTUAL_HOME,
    contextualHome,
  });
};

export const dispatchStartupInfoAndResourceDataAction =
  (appStartupInfoData) => async (dispatch) => {
    const brandResources = get(appStartupInfoData, 'brandResource.resource', {});
    dispatch({
      type: FETCH_APP_STARTUP_INFO,
      appStartupInfo: appStartupInfoData,
    });
    if (!isEmpty(brandResources)) {
      dispatch({
        type: DISPATCH_BRAND_RESOURCES,
        brandResources,
      });
    }
  };

export const fetchAppStartupInfoAction =
  ({ appStartupReqBody, appStartupInfo }, withLoader = true) =>
  async (dispatch) => {
    try {
      withLoader && dispatch(beginAjaxCallAction());
      const appStartupInfoAPIRespone = await fetchAppStartupInfoApi({ appStartupReqBody })
        .then((response) => {
          let appStartupInfoData = appStartupInfo;
          appStartupInfoData = { ...appStartupInfo, ...get(response, 'data', {}) };
          const brandResources = get(appStartupInfoData, 'brandResource.resource', {});
          dispatch({
            type: FETCH_APP_STARTUP_INFO,
            appStartupInfo: appStartupInfoData,
          });
          if (!isEmpty(brandResources)) {
            dispatch({
              type: DISPATCH_BRAND_RESOURCES,
              brandResources,
            });
          }
          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });
      return appStartupInfoAPIRespone;
    } catch (err) {
      console.error(err);
    } finally {
      withLoader && dispatch(endAjaxCallAction());
    }
  };

export const dispatchApplicableBrandModulesAction = (modules) => async (dispatch) => {
  dispatch({
    type: DISPATCH_APPLICABLE_BRAND_MODULES,
    modules,
  });
};

export const dispatchGuestLocationAction =
  ({ guestLocation }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: DISPATCH_GUEST_LOCATION,
        guestLocation,
      });
    } catch (err) {
      console.error(err);
    }
  };

export const dispatchAppResolutionContext = (context) => (dispatch) => {
  dispatch({
    type: DISPATCH_APP_RESOLUTION_CONTEXT,
    appResolutionContext: context,
  });
};

export const dispatchGuestCouponAndDealDataAction = (payload) => (dispatch) => {
  dispatch({
    type: DISPATCH_GUEST_COUPON_AND_DEAL_DATA,
    guestCouponAndDealData: payload,
  });
};

export const clearGuestCouponAndDealDataAction = () => (dispatch) => {
  dispatch({
    type: DISPATCH_GUEST_COUPON_AND_DEAL_DATA,
    guestCouponAndDealData: {},
  });
};
